import React from 'react';
import { graphql } from 'gatsby'

import Layout from '../../components/Layout';


  import ContentfulComponentTopBarNavigation from '../../ContentfulComponents/ContentfulComponentTopBarNavigation';

  import ContentfulComponentFooter from '../../ContentfulComponents/ContentfulComponentFooter';

  

export const query = graphql`
query {
 

    contentfulComponentTopBarNavigation0: contentfulComponentTopBarNavigation (contentful_id: {eq: "eqtJB9MC57s8T9Bki5KQF"}) {
      
          id
          name
          logo {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          buttontext
          linkPath
          options {
            available
            id
            name
            title
            menuText
            order
            contentful_id
          }
          button {
            ... on ContentfulPage {
            available
            id
            name
            title
            buttonColor
            menuText
            contentful_id
            }
            ... on ContentfulComponentPdfLink {
              id
              available
              menuTitle
              pdfLink
            }
          }
          contentful_id

      internal {
        type
      }    
    }
  




    contentfulComponentFooter1: contentfulComponentFooter (contentful_id: {eq: "6xCfAFAjv3Ox5hll40c8JD"}) {
      
          id
          title
          title2
          address
          city
          postalCode
          country
          email
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          options {
            ... on ContentfulPage {
            available
            id
            name
            title
            menuText
            contentful_id
            }
            ... on ContentfulComponentPdfLink {
              id
              available
              menuTitle
              pdfLink
            }
          }
          logo {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          companyRegistrationTitle
          companyRegistrationNumber
          registeredCharityTitle
          registeredCharityNumber
          disclaimer {
            json
          }

      internal {
        type
      }    
    }
  


 
}

`


const Page = (props) => {
  const { contentIds, ...context } = props.pageContext
  const extraData = {
  "menuData": {
    "data": [
      {
        "node": {
          "contentful_id": "6etvL2hiTv5bGNTxlkL7Kq",
          "available": true,
          "buttonColor": null,
          "name": "corporate partnerships",
          "menuText": "Corporate Partnerships",
          "id": "2ea201ed-16c0-5483-be32-cc35f38b2685",
          "order": 9,
          "title": "Corporate Partnerships",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "49b7KwGM5sURVhpQG7ujdj",
          "available": true,
          "buttonColor": "Red",
          "name": "impact",
          "menuText": "Our impact",
          "id": "e4fae9b1-2dcf-5757-96e8-d022349f75ed",
          "order": 4,
          "title": "Our impact",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "75kQi5dHSQ7m644q0Ymn0W",
          "available": true,
          "buttonColor": null,
          "name": "university-partners",
          "menuText": "University Partnerships",
          "id": "7c71099b-62d7-50d7-981d-121d2e6da6f9",
          "order": null,
          "title": "University Partnerships",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "36YqXOBfx317dHllSvFF3y",
          "available": true,
          "buttonColor": "Yellow",
          "name": "volunteer",
          "menuText": "Volunteer",
          "id": "20f50466-ea8f-5791-8c50-9ddbaa951212",
          "order": null,
          "title": "Volunteer",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "iUk18Sv3l5Z9BjzJVUQzJ",
          "available": true,
          "buttonColor": null,
          "name": "join-us",
          "menuText": "Join our team",
          "id": "80323965-447a-5435-bc7b-c5c2080fd617",
          "order": null,
          "title": "Join our team",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "3jiks8wlWK8BHeVbntucZs",
          "available": true,
          "buttonColor": null,
          "name": "reports",
          "menuText": "Impact and annual reports",
          "id": "08cfe802-1dda-5234-a139-cfdb6d27181d",
          "order": null,
          "title": "Impact and annual reports",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "3mWC0EZh9DnO7CApyQfAnx",
          "available": true,
          "buttonColor": null,
          "name": "thank-you-teacher-sign-up",
          "menuText": "Thank you",
          "id": "2b1ac280-0f89-5607-8dce-08459c1674a9",
          "order": null,
          "title": "Thank you for completing the form",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "3HNg3ktKVdHYwaoQuivz4D",
          "available": true,
          "buttonColor": null,
          "name": "who-we-are",
          "menuText": "Who we are",
          "id": "73819c1d-6898-5c00-980d-81f974d4c898",
          "order": 2,
          "title": "Who we are",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "oePiGF87UouTwvYxQ258i",
          "available": true,
          "buttonColor": null,
          "name": "our-team",
          "menuText": "Our Team",
          "id": "1de03f2f-b1d6-5dbe-8145-231fbc1cc17e",
          "order": null,
          "title": "Our Team",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "11ws9UaouYYJbRgA5f8XBp",
          "available": true,
          "buttonColor": null,
          "name": "UE2021Online",
          "menuText": "Universify Online",
          "id": "8469ae96-282b-5412-912d-c635579a2985",
          "order": null,
          "title": "Universify Online",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "7K2UY05PTTjUg0cdGTeUhy",
          "available": true,
          "buttonColor": null,
          "name": "support",
          "menuText": "Support us",
          "id": "c8573105-8b39-596c-b751-1da7f559da3c",
          "order": 6,
          "title": "How to support us",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "4EOzU7cvdd2Kutn1Z0BfaN",
          "available": true,
          "buttonColor": null,
          "name": "blog",
          "menuText": "Blog",
          "id": "9fe88277-7a19-5105-80d3-e4a7a634f1fc",
          "order": null,
          "title": "blog",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "52027bV6tEkflgFEChlL77",
          "available": true,
          "buttonColor": null,
          "name": "content-block-revamp",
          "menuText": "CBR",
          "id": "67028bf8-7e15-5af0-97fb-44dcd0d56f5d",
          "order": null,
          "title": "Content Block Rev test Page",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "2x2X9TACdv3brzderxeVTZ",
          "available": true,
          "buttonColor": null,
          "name": "index",
          "menuText": "Home",
          "id": "0df501b3-c9c4-5022-8f3d-f3c24b6a2926",
          "order": 1,
          "title": "Home",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "6MGN2rvx9XWJQOGVnj03Uw",
          "available": true,
          "buttonColor": null,
          "name": "volunteer-apply",
          "menuText": null,
          "id": "89b8a58a-39ac-5ada-9da8-676113e06447",
          "order": null,
          "title": "Volunteer Apply",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "2LumeyzJngcKGgPeTpdPDJ",
          "available": true,
          "buttonColor": null,
          "name": "FAQstest",
          "menuText": "FAQstest",
          "id": "52b86fb9-9440-5814-8b2c-5ca8a40fc292",
          "order": null,
          "title": "FAQstest",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "4Q8U3CMB89Mc6iVeXeLfXH",
          "available": true,
          "buttonColor": null,
          "name": "child-page-test",
          "menuText": "childPageTest",
          "id": "9895c5f7-70d5-5fc1-9a03-4dd75a97c773",
          "order": null,
          "title": "Child Page Test",
          "pathPrefix": "childpage",
          "parentPage": {
            "available": true,
            "id": "df2de24b-2794-5971-b31a-ee74c0e18820",
            "contentful_id": "7DkYHjrZvsEfvKwFaF3bEG",
            "menuText": "parentPageTest",
            "name": "parent-page-test",
            "order": null,
            "title": "Parent Page Test"
          }
        }
      },
      {
        "node": {
          "contentful_id": "Rc7rTbribNsEkJchC3Rtw",
          "available": true,
          "buttonColor": null,
          "name": "thanks-teacher",
          "menuText": "Thank you ",
          "id": "4dead015-dc9e-5562-9134-9bb0f3dddd86",
          "order": null,
          "title": "Thank you ",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "76YLLRDcW6pIOixvw7E0jS",
          "available": true,
          "buttonColor": null,
          "name": "participating",
          "menuText": "Participating",
          "id": "a13aca16-5623-5d44-8777-4850b6cc3e6f",
          "order": 5,
          "title": "Participating",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "7xtJU3jaDZmCsvEx4Wwf7T",
          "available": true,
          "buttonColor": null,
          "name": "what-we-do",
          "menuText": "What we do",
          "id": "d3cdd37f-b898-5c60-9d0f-b46536807a1a",
          "order": 3,
          "title": "What we do",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "7DkYHjrZvsEfvKwFaF3bEG",
          "available": true,
          "buttonColor": null,
          "name": "parent-page-test",
          "menuText": "parentPageTest",
          "id": "df2de24b-2794-5971-b31a-ee74c0e18820",
          "order": null,
          "title": "Parent Page Test",
          "pathPrefix": "parentpage",
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "1D3IJ6WZ4FmhiJ9KkRFwpk",
          "available": true,
          "buttonColor": null,
          "name": "thank-you",
          "menuText": "Thank you",
          "id": "fb739b68-7fb4-592b-b8bd-2ffcc23e1016",
          "order": null,
          "title": "Thank you",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "24vtwKzhqqeIZmG7PlxYml",
          "available": true,
          "buttonColor": "Orange",
          "name": "teach",
          "menuText": "Teach",
          "id": "ce914650-159e-54eb-92ae-db06505a55d4",
          "order": null,
          "title": "Teach",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "658XaKvcO7UBakrDJycAe1",
          "available": true,
          "buttonColor": "Yellow",
          "name": "donate",
          "menuText": "Donate",
          "id": "f3238957-8bd6-55ad-8f9c-f69e25057711",
          "order": null,
          "title": "Donate",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "4AY9Nmr1PXpcbUOPFJOKjP",
          "available": true,
          "buttonColor": null,
          "name": "privacy-policy",
          "menuText": "Privacy Policy",
          "id": "f79dcf34-bbd3-537a-914e-8252236ba6cf",
          "order": null,
          "title": "Privacy policy",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "62zYK8D8o5jMUHJ8BjY1kf",
          "available": true,
          "buttonColor": null,
          "name": "policies",
          "menuText": "Policies",
          "id": "7e7aa7e5-4c20-5382-867c-4023d944268e",
          "order": null,
          "title": "Policies",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "71Ayu2gvhtMQqrMgKrXGMD",
          "available": true,
          "buttonColor": null,
          "name": "terms-and-conditions",
          "menuText": "Terms and Conditions",
          "id": "739b439e-5153-5d75-9544-4f2a5f4bbe36",
          "order": null,
          "title": "Terms and Conditions",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "6etvL2hiTv5bGNTxlkL7Kq",
          "available": true,
          "buttonColor": null,
          "name": "corporate partnerships",
          "menuText": "Corporate Partnerships",
          "id": "96464b91-c944-5130-9da5-90f030ab4292",
          "order": 9,
          "title": "Corporate Partnerships",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "49b7KwGM5sURVhpQG7ujdj",
          "available": true,
          "buttonColor": "Red",
          "name": "impact",
          "menuText": "Our impact",
          "id": "f6afa49a-b0fb-52e3-bfb7-205dcd135731",
          "order": 4,
          "title": "Our impact",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "75kQi5dHSQ7m644q0Ymn0W",
          "available": true,
          "buttonColor": null,
          "name": "university-partners",
          "menuText": "University Partnerships",
          "id": "3d60c8f5-ceb6-5605-b923-80ffaf30ce05",
          "order": null,
          "title": "University Partnerships",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "36YqXOBfx317dHllSvFF3y",
          "available": true,
          "buttonColor": "Yellow",
          "name": "volunteer",
          "menuText": "Volunteer",
          "id": "152f3805-9839-5428-b2bb-38477ab156e8",
          "order": null,
          "title": "Volunteer",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "iUk18Sv3l5Z9BjzJVUQzJ",
          "available": true,
          "buttonColor": null,
          "name": "join-us",
          "menuText": "Join our team",
          "id": "3f637048-320e-52de-8d9d-cba7b153e877",
          "order": null,
          "title": "Join our team",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "3jiks8wlWK8BHeVbntucZs",
          "available": true,
          "buttonColor": null,
          "name": "reports",
          "menuText": "Impact and annual reports",
          "id": "90b6252c-2260-587d-8701-15cb98f6b44d",
          "order": null,
          "title": "Impact and annual reports",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "3mWC0EZh9DnO7CApyQfAnx",
          "available": true,
          "buttonColor": null,
          "name": "thank-you-teacher-sign-up",
          "menuText": "Thank you",
          "id": "71d5d0e7-6866-5c98-90d3-14b02c467a65",
          "order": null,
          "title": "Thank you for completing the form",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "3HNg3ktKVdHYwaoQuivz4D",
          "available": true,
          "buttonColor": null,
          "name": "who-we-are",
          "menuText": "Who we are",
          "id": "31019af6-fa1f-5ae8-be39-9dd01c9d4ba4",
          "order": 2,
          "title": "Who we are",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "oePiGF87UouTwvYxQ258i",
          "available": true,
          "buttonColor": null,
          "name": "our-team",
          "menuText": "Our Team",
          "id": "acc59cb3-e171-5fae-b17c-ce70a6a6b5cf",
          "order": null,
          "title": "Our Team",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "11ws9UaouYYJbRgA5f8XBp",
          "available": true,
          "buttonColor": null,
          "name": "UE2021Online",
          "menuText": "Universify Online",
          "id": "7b8a3073-2e43-583a-a9da-41d71b3fffe1",
          "order": null,
          "title": "Universify Online",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "7K2UY05PTTjUg0cdGTeUhy",
          "available": true,
          "buttonColor": null,
          "name": "support",
          "menuText": "Support us",
          "id": "1e3a704f-9f24-507e-8794-4db83dffce31",
          "order": 6,
          "title": "How to support us",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "4EOzU7cvdd2Kutn1Z0BfaN",
          "available": true,
          "buttonColor": null,
          "name": "blog",
          "menuText": "Blog",
          "id": "02686437-aca1-5f12-83eb-7f43cdd0e57c",
          "order": null,
          "title": "blog",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "52027bV6tEkflgFEChlL77",
          "available": true,
          "buttonColor": null,
          "name": "content-block-revamp",
          "menuText": "CBR",
          "id": "0539aec7-ea6f-5bbf-98d3-9d8c512f26ea",
          "order": null,
          "title": "Content Block Rev test Page",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "2x2X9TACdv3brzderxeVTZ",
          "available": true,
          "buttonColor": null,
          "name": "index",
          "menuText": "Home",
          "id": "e8e6ee6c-0120-5bb8-a2bc-ed619b277f1a",
          "order": 1,
          "title": "Home",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "6MGN2rvx9XWJQOGVnj03Uw",
          "available": true,
          "buttonColor": null,
          "name": "volunteer-apply",
          "menuText": null,
          "id": "c36e574d-887a-598a-8d48-e1ed07f0296f",
          "order": null,
          "title": "Volunteer Apply",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "2LumeyzJngcKGgPeTpdPDJ",
          "available": true,
          "buttonColor": null,
          "name": "FAQstest",
          "menuText": "FAQstest",
          "id": "7534c431-2b16-5161-8f48-2c5b9cf93674",
          "order": null,
          "title": "FAQstest",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "4Q8U3CMB89Mc6iVeXeLfXH",
          "available": true,
          "buttonColor": null,
          "name": "child-page-test",
          "menuText": "childPageTest",
          "id": "a4bc836a-9443-5e18-aff5-72cd292860dd",
          "order": null,
          "title": "Child Page Test",
          "pathPrefix": "childpage",
          "parentPage": {
            "available": true,
            "id": "d6af2b5b-a154-5584-8078-2d1efdf7e7fd",
            "contentful_id": "7DkYHjrZvsEfvKwFaF3bEG",
            "menuText": "parentPageTest",
            "name": "parent-page-test",
            "order": null,
            "title": "Parent Page Test"
          }
        }
      },
      {
        "node": {
          "contentful_id": "Rc7rTbribNsEkJchC3Rtw",
          "available": true,
          "buttonColor": null,
          "name": "thanks-teacher",
          "menuText": "Thank you ",
          "id": "76daa309-e6cd-57a7-8b3f-d6b1b0828013",
          "order": null,
          "title": "Thank you ",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "76YLLRDcW6pIOixvw7E0jS",
          "available": true,
          "buttonColor": null,
          "name": "participating",
          "menuText": "Participating",
          "id": "5aab5638-ef9a-5c82-b0d5-210f70265d68",
          "order": 5,
          "title": "Participating",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "7xtJU3jaDZmCsvEx4Wwf7T",
          "available": true,
          "buttonColor": null,
          "name": "what-we-do",
          "menuText": "What we do",
          "id": "62172929-58ea-5a56-89b1-1b3145f48afd",
          "order": 3,
          "title": "What we do",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "7DkYHjrZvsEfvKwFaF3bEG",
          "available": true,
          "buttonColor": null,
          "name": "parent-page-test",
          "menuText": "parentPageTest",
          "id": "d6af2b5b-a154-5584-8078-2d1efdf7e7fd",
          "order": null,
          "title": "Parent Page Test",
          "pathPrefix": "parentpage",
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "1D3IJ6WZ4FmhiJ9KkRFwpk",
          "available": true,
          "buttonColor": null,
          "name": "thank-you",
          "menuText": "Thank you",
          "id": "d0c47508-4a97-51b8-9c0f-293b3c204081",
          "order": null,
          "title": "Thank you",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "24vtwKzhqqeIZmG7PlxYml",
          "available": true,
          "buttonColor": "Orange",
          "name": "teach",
          "menuText": "Teach",
          "id": "15779ba1-a7b3-5ff0-bceb-53c21e51ab7d",
          "order": null,
          "title": "Teach",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "658XaKvcO7UBakrDJycAe1",
          "available": true,
          "buttonColor": "Yellow",
          "name": "donate",
          "menuText": "Donate",
          "id": "a8f00697-efef-55f1-b7d9-c0c4a428d0bc",
          "order": null,
          "title": "Donate",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "4AY9Nmr1PXpcbUOPFJOKjP",
          "available": true,
          "buttonColor": null,
          "name": "privacy-policy",
          "menuText": "Privacy Policy",
          "id": "24f40a31-1f94-5f7f-a283-7067ad56176d",
          "order": null,
          "title": "Privacy policy",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "62zYK8D8o5jMUHJ8BjY1kf",
          "available": true,
          "buttonColor": null,
          "name": "policies",
          "menuText": "Policies",
          "id": "79c0435c-c36b-5e22-9a8c-64b0f22d34a0",
          "order": null,
          "title": "Policies",
          "pathPrefix": null,
          "parentPage": null
        }
      },
      {
        "node": {
          "contentful_id": "71Ayu2gvhtMQqrMgKrXGMD",
          "available": true,
          "buttonColor": null,
          "name": "terms-and-conditions",
          "menuText": "Terms and Conditions",
          "id": "530fd00f-0303-5050-ac92-1a176451db2b",
          "order": null,
          "title": "Terms and Conditions",
          "pathPrefix": null,
          "parentPage": null
        }
      }
    ]
  }
};

  return (
    <Layout {...context}>
      
        <ContentfulComponentTopBarNavigation {...props} extraData={extraData} componentId="contentfulComponentTopBarNavigation0" />
      
        <ContentfulComponentFooter {...props} extraData={extraData} componentId="contentfulComponentFooter1" />
      
    </Layout>
  )
}

export default Page;
